<template>
  <div>
    <div>
      <!-- 錯誤共用彈窗 -->
      <div class="searchMask" v-if="getApi_request.isVisible">
        <div class="search_Modal">
          <div class="SearchCon">
            <div class="searchAll">
              <div class="title">
                提示
              </div>

              <div class="searchUser">
                <div class="roomAll" v-if="getApi_request.code == 422">
                  {{ getApi_request.message }}
                </div>
                <div class="roomAll" v-else>
                  {{ getApi_request.msg }}
                </div>
              </div>
            </div>
            <div class="form-group form-actions positionR">
              <div class="sendButton">
                <div class="button floatRight"></div>
                <div class="button floatRight">
                  <CButton
                    block
                    color="dark"
                    variant="outline"
                    @click="resHide('401')"
                    v-if="getApi_request.code == 401"
                    >確定
                  </CButton>
                  <CButton
                    block
                    color="dark"
                    variant="outline"
                    @click="resHide()"
                    v-else
                    >確定
                  </CButton>
                </div>

                <div class="clearBoth"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 送出確認彈窗 -->
    <div>
      <div class="searchMask" v-if="getModal_success.isSuccess">
        <div class="search_Modal">
          <div class="SearchCon">
            <div class="searchAll">
              <div class="title">提示</div>
              <div class="searchUser">
                <div class="roomAll">
                  {{ getModal_success.msg }}
                </div>
              </div>
            </div>
            <div class="form-group form-actions positionR">
              <div class="sendButton">
                <div class="button floatRight"></div>
                <div class="button floatRight">
                  <!-- 555資料更新成功 -->
                  <CButton
                    block
                    color="dark"
                    variant="outline"
                    @click="modalHide(555)"
                    v-if="getModal_success.code == 555"
                    >確定
                  </CButton>

                  <CButton
                    block
                    color="dark"
                    variant="outline"
                    @click="modalHide()"
                    v-else
                    >確定
                  </CButton>
                </div>

                <div class="clearBoth"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import mixin from '../mixins/mixins.vue'
export default {
  name: 'Modal',
  mixins: [mixin],
  data() {
    return {}
  },
  computed: {
    ...mapGetters([
      'getApi_request',
      'getModal_success',
      'get_summaryMonths',
      'get_syearMonth',
    ]),
  },
  watch: {},
  methods: {
    // 關閉彈窗
    resHide(type) {
      if (type == 401) {
        this.$store.commit('HIDE_API_RES')
        window.sessionStorage.clear()
        localStorage.removeItem('getSessionStorageData')
        this.$router.push('/login')
      } else {
        this.$store.commit('HIDE_API_RES')
      }
    },
    modalHide(code, type) {
      // 555資料更新成功
      if (code == 555) {
        this.$store.commit('HIDE_MODAL_SUCCESS')
        window.location.reload()
      } else {
        this.$store.commit('HIDE_MODAL_SUCCESS')
        window.location.assign('/login')
      }
    },
  },
}
</script>
