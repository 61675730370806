var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('div',{staticClass:"clearBoth"}),_c('ValidationObserver',{ref:"form",attrs:{"tag":"form"}},[_c('CForm',[[_c('CCard',[_c('CCardHeader',[_vm._v("公司資訊")]),_c('CCardBody',[_c('validation-provider',{attrs:{"rules":"required","name":"公司名稱"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"col-sm-8 form-group",attrs:{"role":"group"}},[_c('label',{attrs:{"for":"uid-drab8n61hjm"}},[_vm._v(" 公司名稱 ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.companyName),expression:"companyName"}],staticClass:"form-control name",attrs:{"id":"uid-drab8n61hjm","type":"text","placeholder":""},domProps:{"value":(_vm.companyName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.companyName=$event.target.value}}})]),_c('div',{staticClass:"errMsg"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}])}),_c('validation-provider',{attrs:{"rules":"required","name":"公司地址"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"col-sm-8 form-group",attrs:{"role":"group"}},[_c('label',{attrs:{"for":"uid-2x1xhd5qgt6"}},[_vm._v(" 公司地址 ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.companyAddress),expression:"companyAddress"}],staticClass:"form-control address",attrs:{"id":"uid-2x1xhd5qgt6","type":"text","placeholder":""},domProps:{"value":(_vm.companyAddress)},on:{"input":function($event){if($event.target.composing){ return; }_vm.companyAddress=$event.target.value}}})]),_c('div',{staticClass:"errMsg"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}])}),_c('validation-provider',{attrs:{"rules":"required","name":"統一編號"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"col-sm-8 form-group",attrs:{"role":"group"}},[_c('label',{attrs:{"for":"uid-2x1xhd5qgt6"}},[_vm._v(" 統一編號 ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tax),expression:"tax"}],staticClass:"form-control tax_no",attrs:{"id":"uid-2x1xhd5qgt6","type":"text","placeholder":""},domProps:{"value":(_vm.tax)},on:{"input":function($event){if($event.target.composing){ return; }_vm.tax=$event.target.value}}})]),_c('div',{staticClass:"errMsg"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}])}),_c('validation-provider',{attrs:{"rules":"required","name":"負責人"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"col-sm-8 form-group",attrs:{"role":"group"}},[_c('label',{attrs:{"for":"uid-2x1xhd5qgt6"}},[_vm._v(" 負責人 ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.principal),expression:"principal"}],staticClass:"form-control principal",attrs:{"id":"uid-2x1xhd5qgt6","type":"text","placeholder":""},domProps:{"value":(_vm.principal)},on:{"input":function($event){if($event.target.composing){ return; }_vm.principal=$event.target.value}}})]),_c('div',{staticClass:"errMsg"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}])}),_c('validation-provider',{attrs:{"rules":"required|email","name":"Email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"col-sm-8 form-group",attrs:{"role":"group"}},[_c('label',{attrs:{"for":"uid-2x1xhd5qgt6"}},[_vm._v(" Email ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form-control email",attrs:{"id":"uid-2x1xhd5qgt6","type":"text","placeholder":""},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}})]),_c('div',{staticClass:"errMsg"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}])}),_c('div',{staticClass:"col-sm-8 form-group",attrs:{"role":"group","disabled":"disabled"}},[_c('label',{attrs:{"for":"uid-6n2djcu4dqm"}},[_vm._v(" 上層公司 ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.roleType),expression:"roleType"}],staticClass:"form-control role",attrs:{"id":"uid-2yvkqocxfk3"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.roleType=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"0"}},[_vm._v("請選擇")]),_vm._l((_vm.parentCompany),function(value,key){return _c('option',{key:key,attrs:{"data-key":value.id},domProps:{"value":value.id}},[_vm._v(" "+_vm._s(value.name)+" ")])})],2)])],1)],1),_c('CCard',[_c('CCardHeader',[_vm._v("機密欄位")]),_c('CCardBody',[_c('validation-provider',{attrs:{"rules":"required","name":"折扣率"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"col-sm-8 form-group",attrs:{"role":"group"}},[_c('label',{attrs:{"for":"uid-idoqetwdfl"}},[_vm._v(" 折扣率% ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.discount),expression:"discount"}],staticClass:"form-control locationName discount",attrs:{"id":"uid-idoqetwdfl","type":"text","placeholder":""},domProps:{"value":(_vm.discount)},on:{"input":function($event){if($event.target.composing){ return; }_vm.discount=$event.target.value}}})]),_c('div',{staticClass:"errMsg"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}])}),_c('validation-provider',{attrs:{"rules":"required","name":"營業稅率"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"col-sm-8 form-group",attrs:{"role":"group"}},[_c('label',{attrs:{"for":"uid-idoqetwdfl"}},[_vm._v(" 營業稅率% ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.bussiness_tax),expression:"bussiness_tax"}],staticClass:"form-control locationName bussiness_tax",attrs:{"id":"uid-idoqetwdfl","type":"text","placeholder":""},domProps:{"value":(_vm.bussiness_tax)},on:{"input":function($event){if($event.target.composing){ return; }_vm.bussiness_tax=$event.target.value}}})]),_c('div',{staticClass:"errMsg"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}])}),_c('validation-provider',{attrs:{"rules":"required","name":"服務費率"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"col-sm-8 form-group",attrs:{"role":"group"}},[_c('label',{attrs:{"for":"uid-ddjttcu052g"}},[_vm._v(" 服務費率% ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.service_charge),expression:"service_charge"}],staticClass:"form-control service_charge",attrs:{"id":"uid-ddjttcu052g","type":"text","placeholder":""},domProps:{"value":(_vm.service_charge)},on:{"input":function($event){if($event.target.composing){ return; }_vm.service_charge=$event.target.value}}})]),_c('div',{staticClass:"errMsg"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}])}),_c('div',{staticClass:"col-sm-8 form-group",attrs:{"role":"group"}},[_c('label',{attrs:{"for":"uid-ddjttcu052g"}},[_vm._v(" 幣別 ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.currenies),expression:"currenies"}],staticClass:"form-control currency",attrs:{"id":"uid-6vvdf2f2vvr"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.currenies=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.currenies),function(value,key){return _c('option',{key:key,attrs:{"data-key":value.id},domProps:{"value":value.id}},[_vm._v(" "+_vm._s(value.name)+" ")])}),0)])],1),_c('div',{staticClass:"form-group form-actions positionR"},[_c('div',{staticClass:"sendButton"},[_c('div',{staticClass:"button floatRight"}),_c('div',{staticClass:"button floatRight"},[_c('CButton',{attrs:{"block":"","color":"dark","variant":"outline"},on:{"click":function($event){return _vm.sendBtn()}}},[_vm._v("送出 ")])],1),_c('div',{staticClass:"clearBoth"})])])],1)]],2)],1),_c('Modal')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"topButton"},[_c('div',{staticClass:"titleFont noButtonTitle floatLeft"},[_vm._v("新增公司資料")])])}]

export { render, staticRenderFns }