<template>
  <div>
    <div class="topButton">
      <div class="titleFont noButtonTitle floatLeft">新增公司資料</div>
    </div>
    <div class="clearBoth"></div>
    <ValidationObserver tag="form" ref="form">
      <CForm>
        <template>
          <CCard>
            <CCardHeader>公司資訊</CCardHeader>
            <CCardBody>
              <validation-provider
                rules="required"
                v-slot="{ errors }"
                name="公司名稱"
              >
                <div role="group" class="col-sm-8 form-group">
                  <label for="uid-drab8n61hjm" class=""> 公司名稱 </label>
                  <input
                    id="uid-drab8n61hjm"
                    type="text"
                    placeholder=""
                    class="form-control name"
                    v-model="companyName"
                  />
                </div>
                <div class="errMsg">
                  <span>{{ errors[0] }}</span>
                </div>
              </validation-provider>
              <validation-provider
                rules="required"
                v-slot="{ errors }"
                name="公司地址"
              >
                <div role="group" class="col-sm-8 form-group">
                  <label for="uid-2x1xhd5qgt6" class=""> 公司地址 </label>
                  <input
                    id="uid-2x1xhd5qgt6"
                    type="text"
                    placeholder=""
                    class="form-control address"
                    v-model="companyAddress"
                  />
                </div>

                <div class="errMsg">
                  <span>{{ errors[0] }}</span>
                </div>
              </validation-provider>
              <validation-provider
                rules="required"
                v-slot="{ errors }"
                name="統一編號"
              >
                <div role="group" class="col-sm-8 form-group">
                  <label for="uid-2x1xhd5qgt6" class=""> 統一編號 </label>
                  <input
                    id="uid-2x1xhd5qgt6"
                    type="text"
                    placeholder=""
                    class="form-control tax_no"
                    v-model="tax"
                  />
                </div>

                <div class="errMsg">
                  <span>{{ errors[0] }}</span>
                </div>
              </validation-provider>
              <validation-provider
                rules="required"
                v-slot="{ errors }"
                name="負責人"
              >
                <div role="group" class="col-sm-8 form-group">
                  <label for="uid-2x1xhd5qgt6" class=""> 負責人 </label>
                  <input
                    id="uid-2x1xhd5qgt6"
                    type="text"
                    placeholder=""
                    class="form-control principal"
                    v-model="principal"
                  />
                </div>

                <div class="errMsg">
                  <span>{{ errors[0] }}</span>
                </div>
              </validation-provider>
              <validation-provider
                rules="required|email"
                v-slot="{ errors }"
                name="Email"
              >
                <div role="group" class="col-sm-8 form-group">
                  <label for="uid-2x1xhd5qgt6" class=""> Email </label>
                  <input
                    id="uid-2x1xhd5qgt6"
                    type="text"
                    placeholder=""
                    class="form-control email"
                    v-model="email"
                  />
                </div>

                <div class="errMsg">
                  <span>{{ errors[0] }}</span>
                </div>
              </validation-provider>
              <div role="group" class="col-sm-8 form-group" disabled="disabled">
                <label for="uid-6n2djcu4dqm" class=""> 上層公司 </label>
                <select
                  id="uid-2yvkqocxfk3"
                  class="form-control role"
                  v-model="roleType"
                >
                  <option value="0">請選擇</option>
                  <option
                    :data-key="value.id"
                    :value="value.id"
                    v-for="(value, key) in parentCompany"
                    :key="key"
                  >
                    {{ value.name }}
                  </option>
                </select>
              </div>
            </CCardBody>
          </CCard>
          <CCard>
            <CCardHeader>機密欄位</CCardHeader>
            <CCardBody>
              <validation-provider
                rules="required"
                v-slot="{ errors }"
                name="折扣率"
              >
                <div role="group" class="col-sm-8 form-group">
                  <label for="uid-idoqetwdfl" class=""> 折扣率% </label>
                  <input
                    id="uid-idoqetwdfl"
                    type="text"
                    placeholder=""
                    class="form-control locationName discount"
                    v-model="discount"
                  />
                </div>

                <div class="errMsg">
                  <span>{{ errors[0] }}</span>
                </div>
              </validation-provider>
              <validation-provider
                rules="required"
                v-slot="{ errors }"
                name="營業稅率"
              >
                <div role="group" class="col-sm-8 form-group">
                  <label for="uid-idoqetwdfl" class=""> 營業稅率% </label>
                  <input
                    id="uid-idoqetwdfl"
                    type="text"
                    placeholder=""
                    class="form-control locationName bussiness_tax"
                    v-model="bussiness_tax"
                  />
                </div>

                <div class="errMsg">
                  <span>{{ errors[0] }}</span>
                </div>
              </validation-provider>
              <validation-provider
                rules="required"
                v-slot="{ errors }"
                name="服務費率"
              >
                <div role="group" class="col-sm-8 form-group">
                  <label for="uid-ddjttcu052g" class=""> 服務費率% </label>
                  <input
                    id="uid-ddjttcu052g"
                    type="text"
                    placeholder=""
                    class="form-control service_charge"
                    v-model="service_charge"
                  />
                </div>

                <div class="errMsg">
                  <span>{{ errors[0] }}</span>
                </div>
              </validation-provider>
              <div role="group" class="col-sm-8 form-group">
                <label for="uid-ddjttcu052g" class=""> 幣別 </label>
                <select
                  id="uid-6vvdf2f2vvr"
                  class="form-control currency"
                  v-model="currenies"
                >
                  <option
                    :data-key="value.id"
                    :value="value.id"
                    v-for="(value, key) in currenies"
                    :key="key"
                  >
                    {{ value.name }}
                  </option>
                </select>
              </div>
            </CCardBody>
            <div class="form-group form-actions positionR">
              <div class="sendButton">
                <div class="button floatRight"></div>
                <div class="button floatRight">
                  <CButton
                    block
                    color="dark"
                    variant="outline"
                    @click="sendBtn()"
                    >送出
                  </CButton>
                </div>

                <div class="clearBoth"></div>
              </div>
            </div>
          </CCard>
        </template>
      </CForm>
    </ValidationObserver>

    <!-- 共用錯誤彈窗 -->
    <Modal></Modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import Modal from '../Modal.vue'
export default {
  name: 'EditAccount',
  data() {
    return {
      companyName: '',
      companyAddress: '',
      tax: '',
      principal: '',
      email: '',
      discount: '',
      bussiness_tax: '',
      service_charge: '',
      currency: 1,
      currenies: [
        { id: 1, name: '台幣' },
        { id: 2, name: '美金' },
      ],
      billingAccountList: [
        {
          account_id: 1,
          account_name: 'ba_name1',
          project_code: 'ba0001',
          status: '1',
        },
        {
          account_id: 2,
          account_name: 'ba_name2',
          project_code: 'ba0002',
          status: '1',
        },
        {
          account_id: 2,
          account_name: 'ba_name2',
          project_code: 'ba0002',
          status: '1',
        },
      ],
      parentCompany: [
        { id: 1, name: 'Dynacloud' },
        { id: 2, name: 'ECV' },
      ],
    }
  },
  components: {
    Modal,
  },
  computed: {
    ...mapGetters([]),
    fields() {
      return [
        {
          key: 'account_id',
          label: 'Billing Account ID',
        },

        {
          key: 'account_name',
          label: 'Billing Account Name',
        },
        {
          key: 'project_code',
          label: 'Project Code',
        },
        {
          key: 'status',
          label: '狀態',
        },
        // {
        //   key: 'edit',
        //   label: '',
        //   _style: 'width:10%',
        //   sorter: false,
        //   filter: false,
        // },
      ]
    },
  },
  methods: {
    click() {
      //   console.log('test')
    },
    sendBtn() {
      this.$refs.form.validate().then((valid) => {
        if (valid) {
          //   送出資料
          let data = {
            id: this.$route.params.id,
            companyName: $('.companyName').val(),
            companyAddress: $('.companyAddress').val(),
            tax: $('.tax').val(),
            principal: $('.principal').val(),
            email: $('.email').val(),
            discount: $('.discount').val(),
            bussiness_tax: $('.bussiness_tax').val(),
            service_charge: $('.service_charge').val(),
            currency: $('.currency').val(),
          }
          //   console.log('data', data)
          this.$store.dispatch('actionCompanyUpdate', data)
        } else {
          console.log('NO')
        }
      })
    },
  },
  mounted() {},
  created() {},
}
</script>
