<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
  data() {
    return {
      tax: 0,
      cost: 0,
      total: 0,
      yearMonth: '',
    }
  },
  computed: {
    ...mapGetters(['get_summaryMonths', 'get_syearMonth']),
  },
  watch: {},
}
</script>
